import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api`;
// || 'http://localhost:5001/api';

// Define the base article interface
export interface IArticle {
  id?: number;
  title: string;
  content: string;
  author: string;
  author_first_name: string;
  author_last_name: string;
  category: "Technology" | "Politics" | "Culture" | "Science";
  tags: string[];
  image_url?: string;
  status?: "draft" | "published" | "archived";
  created_at?: Date;
  updated_at?: Date;
}

// Interface for creating a new article
export interface ICreateArticle {
  title: string;
  content: string;
  author: string;
  category: "Technology" | "Politics" | "Culture" | "Science";
  tags: string[];
  image_url?: string;
  status?: "draft" | "published" | "archived";
}

export interface IUpdateArticle extends Partial<ICreateArticle> {}

// Article service with CRUD operations
export const articleService = {
  // Get all articles
  getAll: async (): Promise<IArticle[]> => {
    try {
      console.log(`api url: ${API_BASE_URL}`);
      const response = await axios.get<IArticle[]>(`${API_BASE_URL}/articles`);
      console.log(`response: ${response.data}`);

      return response.data;
    } catch (error) {
      console.error("Error fetching articles:", error);
      throw error;
    }
  },

  // Get articles by category
  getByCategory: async (category: string): Promise<IArticle[]> => {
    try {
      const response = await axios.get<IArticle[]>(
        `${API_BASE_URL}/articles/category/${category}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching articles by category:", error);
      throw error;
    }
  },

  // Get articles by author
  getByAuthor: async (authorId: string): Promise<IArticle[]> => {
    try {
      const response = await axios.get<IArticle[]>(
        `${API_BASE_URL}/articles/author/${authorId}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching articles:", error);
      throw error;
    }
  },

  // Get a single article by ID
  getById: async (id: number): Promise<IArticle> => {
    try {
      const response = await axios.get<IArticle>(
        `${API_BASE_URL}/articles/${id}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching article:", error);
      throw error;
    }
  },

  // Create a new article (requires auth)
  create: async (article: ICreateArticle): Promise<IArticle> => {
    try {
      const response = await axios.post<IArticle>(
        `${API_BASE_URL}/articles`,
        article,
      );
      return response.data;
    } catch (error) {
      console.error("Error creating article:", error);
      throw error;
    }
  },

  // Update an existing article (requires auth)
  update: async (id: string, article: IUpdateArticle): Promise<IArticle> => {
    try {
      const response = await axios.put<IArticle>(
        `${API_BASE_URL}/articles/${id}`,
        article,
      );
      return response.data;
    } catch (error) {
      console.error("Error updating article:", error);
      throw error;
    }
  },

  // Delete an article (requires auth)
  delete: async (id: string): Promise<void> => {
    try {
      await axios.delete(`${API_BASE_URL}/articles/${id}`);
    } catch (error) {
      console.error("Error deleting article:", error);
      throw error;
    }
  },

  // Save article as draft
  saveDraft: async (article: ICreateArticle): Promise<IArticle> => {
    try {
      const response = await axios.post<IArticle>(
        `${API_BASE_URL}/articles/draft`,
        { ...article, status: "draft" },
      );
      return response.data;
    } catch (error) {
      console.error("Error saving draft:", error);
      throw error;
    }
  },

  // Get user's draft articles
  getDrafts: async (): Promise<IArticle[]> => {
    try {
      const response = await axios.get<IArticle[]>(
        `${API_BASE_URL}/articles/drafts`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching drafts:", error);
      throw error;
    }
  },
};
