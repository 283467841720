import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./ArticlesPage.scss";
import { articleService } from "../services/articleService";
import { IArticle } from "../services/articleService";

// Define the Article interface

const ArticlesPage: React.FC = () => {
  const { category } = useParams();
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState(true);
  // const filteredArticles = category
  //     ? articles.filter(article => article.category === category)
  //     : articles;

  const fetchArticlesByCategory = async () => {
    try {
      if (!category) return;
      setLoading(true);
      const data = await articleService.getByCategory(category);
      setArticles(data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticlesByCategory();
  }, [category]);

  return (
    <div className="articles-page">
      <h1>
        {(category || "All").charAt(0).toUpperCase() +
          (category || "all").slice(1)}
      </h1>
      <div className="articles-grid">
        {articles.length === 0 ? (
          <div className="no-articles">
            <p>New content coming soon!</p>
          </div>
        ) : (
          articles.map((article: IArticle) => (
            <Link
              to={`/article/id/${article.id}`}
              key={article.id}
              className="article-card"
            >
              <img src={article.image_url} alt={article.title} />
              <h2>{article.title}</h2>
              <p>{article.content.substring(0, 150)}...</p>
              <div className="article-meta">
                <span>
                  By {article.author_first_name} {article.author_last_name}
                </span>
                <span>
                  {article.created_at
                    ? new Date(article.created_at).toLocaleDateString()
                    : "Date not available"}
                </span>
              </div>
              <span className="read-more">Read More →</span>
            </Link>
          ))
        )}
      </div>
      <div className="articles-back">
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  );
};

export default ArticlesPage;
