import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArticleForm from "../components/ArticleForm/ArticleForm";
import { articleService, IArticle } from "../services/articleService";
import "./SubmitArticlePage.scss";

const SubmitArticlePage: React.FC = () => {
  return (
    <div className="submit-article-page">
      <h1>Submit Article</h1>
    </div>
  );
};

export default SubmitArticlePage;
