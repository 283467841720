import React from "react";
import "./Navbar.scss";

import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <div>
      <nav className="navbar">
        <div className="nav-left">
          <Link to="/">Home</Link>
          <Link to="/articles/Technology">Technology</Link>
          <Link to="/articles/Politics">Politics</Link>
          <Link to="/articles/Culture">Culture</Link>
        </div>
        <div className="nav-right"></div>
      </nav>
    </div>
  );
};

export default NavBar;
