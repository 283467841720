// export default ArticleDetailPage;
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IArticle, articleService } from "../services/articleService";
import "./ArticleDetailPage.scss";

const ArticleDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<IArticle | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchArticleById = async () => {
    try {
      if (!id) return;
      setLoading(true);
      const data = await articleService.getById(parseInt(id));
      setArticle(data);
    } catch (error) {
      console.error("Error fetching article:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleById();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (!article) return <div>Article not found</div>;

  return (
    <div className="article-detail">
      <h1>{article.title}</h1>
      <p>
        By {article.author_first_name} {article.author_last_name} | Published
        on:{" "}
        {article.created_at
          ? new Date(article.created_at).toLocaleDateString()
          : "Date not available"}
      </p>
      {article.image_url && (
        <div className="article-image-wrapper">
          <img
            src={article.image_url}
            alt={article.title}
            className="article-image"
          />
        </div>
      )}
      <div className="article-content">
        {article.content
          .split("\n")
          .filter((line) => line.trim() !== "") // Filter out empty lines
          .map((line, index) => (
            <p key={index}>{line}</p>
          ))}
      </div>
      <div className="article-back">
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  );
};

export default ArticleDetailPage;
