import React, { useEffect, useState } from "react";
import "./HomePage.scss";
import { IArticle, articleService } from "../services/articleService";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      const data = await articleService.getAll();
      setArticles(data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  const id = "6";
  const [article, setArticle] = useState<IArticle | null>(null);

  const fetchArticleById = async (id: string) => {
    try {
      if (!id) return;
      setLoading(true);
      const data = await articleService.getById(parseInt(id));
      setArticle(data);
    } catch (error) {
      console.error("Error fetching article:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticleById(id);
  }, [id]);

  const formatDate = (date: Date | string | undefined) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString();
  };

  if (loading) return <div>Loading...</div>;
  if (!article) return <div>Article not found</div>;

  return (
    <div className="home-page">
      <section className="hero">
        <h1>Heretic</h1>
        <p>
          her·e·tic /ˈherəˌtik/ (n): a person holding an opinion at odds with
          what is generally accepted, especially in religion, politics, or
          social norms.
        </p>
      </section>
      <h2>Featured Articles</h2>
      <div className="article-preview">
        <Link
          to={`/article/id/${article.id}`}
          style={{ textDecoration: "none", color: "white" }}
        >
          <h1>{article.title}</h1>
          {article.image_url && (
            <img src={article.image_url} alt={article.title} />
          )}
          <p>
            {article.content
              ? `${article.content.substring(0, 150)}...`
              : "No content available."}
          </p>
          <p>
            By {article.author_first_name} {article.author_last_name}
          </p>
          <p>Category: {article.category}</p>
        </Link>
      </div>
      {/* ... rest of your code ... */}
    </div>
  );
};

export default HomePage;
